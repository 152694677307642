<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
      >
        <product-selector
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)"
          :filterOrganization="salesDelivery.organization.id"
          :filterWarehouse="getWarehouse()"
          @productChanged="(productId) => (selectedProduct = productId)"
          :filterIds="getSalesOrderProductsIds()"
        />
      </div>
      <el-table
        class="table-responsive align-items-center table-flush sales-delivery-product-list"
        header-row-class-name="thead-light"
        :data="filteredSalesDeliveryItemsModel"
        type="expand"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="80px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="salesDelivery.organization.id"
                :product="salesDeliveryItemsModel[row.id].salesDeliverable.id"
                :filterWarehouse="getWarehouse()"
                :filterSupplier="
                  salesDelivery.issuer ? salesDelivery.issuer.id : null
                "
                :filterIds="getSalesOrderProductsIds()"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                @productChanged="
                  (productId, product) => {
                    modelProductChanged(
                      productId,
                      product,
                      salesDeliveryItemsModel[row.id]
                    );
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.salesDeliverable && row.id > 0"
              :object="row.salesDeliverable"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.EXPECTED_QUANTITY')"
          prop="quantity"
          min-width="40px"
          v-if="salesDelivery.status == DELIVERY_STATUS_VALIDATED"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="salesDelivery.status == DELIVERY_STATUS_DRAFT"
              class="my-5"
              :placeholder="$t('COMMON.EXPECTED_QUANTITY')"
              v-model="salesDeliveryItemsModel[row.id].expected_quantity"
              input-classes="form-control-alternative"
            />
            <span v-if="salesDelivery.status != DELIVERY_STATUS_DRAFT">
              {{ salesDeliveryItemsModel[row.id].expected_quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            salesDelivery.status == DELIVERY_STATUS_VALIDATED
              ? $t('COMMON.DELIVERED_QUANTITY')
              : $t('COMMON.QUANTITY')
          "
          prop="quantity"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="salesDelivery.status == DELIVERY_STATUS_DRAFT"
              class="my-5"
              :placeholder="
                salesDelivery.status == DELIVERY_STATUS_VALIDATED
                  ? $t('COMMON.DELIVERED_QUANTITY')
                  : $t('COMMON.QUANTITY')
              "
              v-model="salesDeliveryItemsModel[row.id].quantity"
              input-classes="form-control-alternative"
            />
            <span v-if="salesDelivery.status != DELIVERY_STATUS_DRAFT">
              {{ salesDeliveryItemsModel[row.id].quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="20px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Delete"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_DELETE_PRODUCTS) &&
                salesDelivery.status == DELIVERY_STATUS_DRAFT
              "
            >
              <a
                type="text"
                @click="deleteSalesDeliveryItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div
        class="row px-3 mt-3"
        v-if="salesDelivery.status == DELIVERY_STATUS_DRAFT"
      >
        <base-button
          type="info"
          class="btn-sm mr-1 col-10"
          style="width: 100%"
          v-if="canAddLine()"
          @click="
            () => {
              addLine();
            }
          "
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
        <base-button
          type="primary"
          class="btn-sm ml-1 col"
          style="width: 100%"
          @click="saveSalesDeliveryItems()"
        >
          <i class="fas fa-save"></i>
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
} from "@/constants/deliveries";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";

export default {
  components: {
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    salesDelivery: {
      type: Object,
      default: null,
      description: "salesDelivery",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredSalesDeliveryItemsModel: [],
      loading: false,
      selectedProduct: null,
      salesDeliveryItemsModel: {},
      deletedSalesDeliveryItems: {},
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_VALIDATED: DELIVERY_STATUS_VALIDATED,
    };
  },

  computed: {},

  watch: {
    salesDelivery: {
      handler: "refreshSalesDeliveryItems",
      immediate: true,
    },
    selectedProduct: {
      handler: "filterSalesDeliveryItems",
      immediate: true,
    },
  },

  mounted() {
    this.refreshSalesDeliveryItems();
  },

  methods: {
    getWarehouse() {
      if (this.salesDelivery.destinationWarehouse) {
        return this.salesDelivery.destinationWarehouse.id;
      }
      return null;
    },

    getSalesOrderProductsIds() {
      if (!this.salesDelivery.salesOrder.deliveryItemsState) {
        return null;
      }
      const remainingItemsIds = Object.values(
        this.salesDelivery.salesOrder.deliveryItemsState.remainingItems
      )
        .filter((item) => item.quantity > 0)
        .map((item) => item.id);
      if (remainingItemsIds.length > 0) {
        return remainingItemsIds;
      }
      return null;
    },

    modelProductChanged(productId, product, model) {
      if (product.warehouseProduct) {
        model.salesDeliverable = {
          type: "warehouse-products",
          id: product.warehouseProduct.id.toString(),
        };
        model.code =
          product.warehouseProduct.sku || product.sku || product.code;
      } else if (product.supplierProduct) {
        model.salesDeliverable = {
          type: "supplier-products",
          id: product.supplierProduct.id.toString(),
        };
        model.code = product.supplierProduct.sku || product.sku || product.code;
      } else {
        model.salesDeliverable.id = productId;
        model.code = product.code;
      }
    },

    canAddLine() {
      const deliveryItemsIds = Object.values(this.salesDeliveryItemsModel).map(
        (item) => item.salesDeliverable.id
      );
      if (!this.salesDelivery.salesOrder.deliveryItemsState) {
        return true;
      }
      const remainingItemsIds = Object.values(
        this.salesDelivery.salesOrder.deliveryItemsState.remainingItems
      ).filter((item) => !deliveryItemsIds.includes(item.id));
      return remainingItemsIds.length > 0;
    },

    async filterSalesDeliveryItems() {
      this.filteredSalesDeliveryItemsModel = Object.values(
        this.salesDeliveryItemsModel
      ).filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.salesDeliverable.id) {
            return false;
          }
        }
        return true;
      });
    },

    async deleteSalesDeliveryItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
      }

      this.deletedSalesDeliveryItems[row.id] = _.cloneDeep(row);
      delete this.salesDeliveryItemsModel[row.id];
      this.filterSalesDeliveryItems();
    },

    async saveSalesDeliveryItems() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const line of Object.values(this.deletedSalesDeliveryItems)) {
          if (line.id > 0) {
            await this.$store.dispatch("salesDeliveryItems/destroy", line.id);
          }
        }
        this.deletedSalesDeliveryItems = {};

        for (const line of Object.values(this.salesDeliveryItemsModel)) {
          const salesDeliveryItemData = _.cloneDeep(line);
          salesDeliveryItemData.expected_quantity =
            salesDeliveryItemData.quantity;
          if (line.id > 0) {
            await this.$store.dispatch(
              "salesDeliveryItems/update",
              salesDeliveryItemData
            );
          } else {
            if (salesDeliveryItemData.salesDeliverable.id) {
              delete salesDeliveryItemData.id;
              await this.$store.dispatch(
                "salesDeliveryItems/add",
                salesDeliveryItemData
              );
            }
          }
        }
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        this.$emit("salesDeliveryItemsUpdated");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.salesDeliveryItemsModel)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newSalesDeliveryItem = {
        type: "sales-delivery-items",
        id: newLineId,
        quantity: 0,
        relationshipNames: ["salesDeliverable", "salesDelivery"],
        salesDeliverable: {
          type: "products",
          id: null,
        },
        salesDelivery: {
          type: "sales-deliveries",
          id: this.salesDelivery.id,
        },
      };
      this.salesDeliveryItemsModel[newLineId] =
        _.cloneDeep(newSalesDeliveryItem);
      this.filterSalesDeliveryItems();
    },

    refreshSalesDeliveryItems() {
      const salesDeliveryItemsModelData = {};
      for (const item of this.salesDelivery.items) {
        salesDeliveryItemsModelData[item.id] = {
          type: "sales-delivery-items",
          id: item.id,
          quantity: item.quantity,
          expected_quantity: item.expected_quantity,
          relationshipNames: ["salesDeliverable", "salesDelivery"],
          salesDeliverable: {
            type: item.salesDeliverable.type,
            id: item.salesDeliverable.id,
            name: item.salesDeliverable.name,
          },
          salesDelivery: {
            type: "sales-deliveries",
            id: item.salesDelivery.id,
          },
        };
      }
      this.salesDeliveryItemsModel = salesDeliveryItemsModelData;
      this.filterSalesDeliveryItems();
    },
  },
};
</script>
<style>
.sales-delivery-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.sales-delivery-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
