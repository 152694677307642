<template>
  <el-select
    v-model="salesOrderModel"
    @change="salesOrderChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.SALES_ORDER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getSalesOrders"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.SALES_ORDER')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="salesOrder in salesOrders"
      :key="salesOrder.id"
      :value="salesOrder.id"
      :label="`${salesOrder.code}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "sales-order-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    salesOrder: {
      type: String,
      default: null,
      description: "SalesOrder id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "SalesOrder status",
    },
  },

  data() {
    return {
      salesOrderModel: this.salesOrder,
      salesOrders: {},
    };
  },

  setup() {},

  created() {
    this.getSalesOrders(null, this.salesOrder);
  },

  methods: {
    async getSalesOrders(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { code: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.filterStatus },
          };
        }

        await this.$store.dispatch("salesOrders/list", params);
        const salesOrdersArr = await this.$store.getters["salesOrders/list"];
        this.salesOrders = {};
        salesOrdersArr.forEach((salesOrder) => {
          this.salesOrders[salesOrder.id] = salesOrder;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    salesOrderChanged(salesOrderId) {
      const salesOrder = Object.values(this.salesOrders).find(
        (item) => item.id === salesOrderId
      );
      this.$emit("salesOrderChanged", salesOrderId, salesOrder);
    },
  },

  watch: {
    salesOrder(salesOrder) {
      if (salesOrder) {
        this.salesOrderModel = salesOrder;
        if (salesOrder !== this.salesOrderModel) {
          this.getSalesOrders(null, salesOrder);
        }
      } else {
        this.salesOrderModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getSalesOrders();
    },
    filterReseller() {
      this.getSalesOrders();
    },
    filterStatus() {
      this.getSalesOrders();
    },
  },
};
</script>
