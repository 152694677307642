<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row mb-4">
      <div class="col-8">
        <h3 class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</h3>
      </div>
    </div>

    <sales-delivery-view-details-sales-delivery-item-list
      :salesDelivery="salesDelivery"
      @salesDeliveryItemsUpdated="salesDeliveryItemsUpdated"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import SalesDeliveryViewDetailsSalesDeliveryItemList from "./SalesDeliveryViewDetailsSalesDeliveryItemList.vue";

export default {
  name: "sales-delivery-view-details",

  components: {
    SalesDeliveryViewDetailsSalesDeliveryItemList,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: ["salesDelivery"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    salesDeliveryItemsUpdated() {
      this.$emit("salesDeliveryItemsUpdated");
    },
  },

  mounted() {},

  watch: {},
};
</script>
