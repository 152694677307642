<template>
  <div class="container-fluid">
    <sales-delivery-form
      :loading="loading"
      :salesDeliveryData="salesDelivery"
      :formErrors="formErrors"
      @salesDeliveriesubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSalesDelivery from "../defaultSalesDelivery";
import SalesDeliveryForm from "../partials/SalesDeliveryForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SalesDeliveryForm,
  },

  mixins: [alertLeave],

  data() {
    const salesDelivery = cloneDeep(defaultSalesDelivery);
    salesDelivery.taxes = cloneDeep(defaultTaxes);
    if (this.$currentUserIsLevelOrganization()) {
      salesDelivery.organization = {
        type: "organizations",
        id: this.$currentUserOrganizationId(),
      };
    }
    return {
      salesDelivery: salesDelivery,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(salesDelivery) {
      this.loading = true;
      const salesDeliveryData = cloneDeep(salesDelivery);
      try {
        await this.$store.dispatch("salesDeliveries/add", salesDeliveryData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_ADDED"),
        });
        const salesDelivery = await this.$store.getters[
          "salesDeliveries/salesDelivery"
        ];
        this.$emit("onViewSalesDelivery", salesDelivery, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
